import React, { useState, useRef, useEffect } from "react";
import Tesseract from "tesseract.js";
import Webcam from "react-webcam";
import { Box, Button, Grid, Typography, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation, useNavigate } from "react-router-dom";

const TextScan = ({}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [ocrText, setOcrText] = useState("");
  const [facingMode, setFacingMode] = useState("environment"); // 'user' for front camera, 'environment' for rear camera
  const webcamRef = useRef(null);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [isLoading, setIsLoading] = useState(false);
  const windowWidth = useRef(window.innerWidth);
  const windowHeight = useRef(window.innerHeight);
  const [ocr, setOCR] = useState(false);

  console.log("width: ", windowWidth.current);
  console.log("height: ", windowWidth.current);
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const capture = async () => {
    setIsLoading(true);
    const imageSrc = webcamRef.current.getScreenshot();
    const processImage = async (imageData) => {
      const {
        data: { text },
      } = await Tesseract.recognize(imageData, "eng");
      console.log(text);
      setOcrText(text);
    };
    await processImage(imageSrc);
    setIsLoading(false);
  };

  return (
    <Box
      sx={{
        width: "100wh",
        maxHeight: "100vh",
        height: "100vh",
        background: "#0068B3",
      }}
    >
      <Grid
        container
        sx={{
          height: windowWidth.current,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "10%",
        }}
      >
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{ facingMode: facingMode }}
            width={windowWidth.current}
            height={windowWidth.current}
          />
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              marginTop: "5%",
              color: "white",
              background: "#cf2e2e",
              "&:hover": {
                backgroundColor: "#cf2e2e",
                color: "#FFFFFF",
              },
              marginBottom: "5%",
            }}
            onClick={() => {
              if (!isLoading) {
                capture();
              }
            }}
            startIcon={isLoading && <CircularProgress size={20} />}
          >
            {isLoading ? "Loading..." : `Click here to Scan`}
          </Button>
        </Grid>
        <Grid item sm={11} xs={11} md={11}>
          <Typography
            sx={{ fontWeight: "800", fontSize: windowWidth.current / 25 }}
          >
            Model Name : {location.state.modelName ?? ""}
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ fontWeight: "800", fontSize: windowWidth.current / 25 }}
            >
              Co-ordinates : {location.state.latlong ?? ""}
            </Typography>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Typography
              sx={{ fontWeight: "800", fontSize: windowWidth.current / 25 }}
            >
              Street Name :
            </Typography>
            <Typography
              sx={{ fontWeight: "800", fontSize: windowWidth.current / 25 }}
            >
              {location.state.address ?? ""}
            </Typography>
          </div>

          <Typography
            sx={{ fontWeight: "800", fontSize: windowWidth.current / 25 }}
          >
            Becon Distance : 50m away
          </Typography>
        </Grid>
        <Grid item sm={11} xs={11} md={11} sx={{ marginTop: "2%" }}>
          <TextField
            id="outlined-basic"
            label="OCR Values"
            variant="outlined"
            sx={{
              width: "90%",
              marginTop: "1%",
              "& label.Mui-focused": { color: "white" },
              "& .MuiInputBase-input::placeholder": {
                color: "white",
              },
              "& .MuiInputBase-input": {
                color: "#FFFFFF",
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
                "& fieldset": {
                  borderColor: "white",
                },
              },
            }}
            value={ocrText}
            onChange={(e) => setOcrText(e.target.value)}
            placeholder="OCR Values"
          />
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "5%",
          }}
        >
          <Button
            sx={{
              color: "white",
              background: "#cf2e2e",
              "&:hover": {
                backgroundColor: "#cf2e2e",
                color: "#FFFFFF",
              },
            }}
            onClick={() => navigate("/")}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TextScan;
