import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CameraScreen from "./Screens/CameraScreen";
import OcrScreen from "./Screens/OcrScreen";
import ScanComp from "./Screens/ScanComp";
import TextScan from "./Screens/TextScan";
import MainScreen from "./Screens/MainScreen";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainScreen />} />
        <Route path="/scan" element={<ScanComp />} />
        <Route path="/cam" element={<CameraScreen />} />
        <Route path="/ocr" element={<TextScan />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
