import React, { useState, useRef, useEffect } from "react";
import Tesseract from "tesseract.js";
import Webcam from "react-webcam";
import { Box, Button, Grid, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const OcrScreen = () => {
  const [ocrText, setOcrText] = useState("");
  const [facingMode, setFacingMode] = useState("environment"); // 'user' for front camera, 'environment' for rear camera
  const webcamRef = useRef(null);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [isLoading, setIsLoading] = useState(false);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const processImage = async (imageData) => {
    const {
      data: { text },
    } = await Tesseract.recognize(imageData, "eng");
    setOcrText(text);
  };

  const capture = async () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    const imageSrc = webcamRef.current.getScreenshot();
    await processImage(imageSrc);
  };

  return (
    <Box
      sx={{
        width: "100wh",
        height: "100vh",
      }}>
      <Grid container>
        <Grid
          item
          xs={12}
          md={12}
          xl={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyItems: "center",
          }}>
          <div style={{ width: 400, height: 500, overflow: "hidden" }}>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{ facingMode: facingMode }}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          xl={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Button
            variant="contained"
            sx={{
              height: screenSize.height / 15,
              width: screenSize.width < screenSize.height ? "30%" : "10%",
              fontSize:
                screenSize.width < screenSize.height
                  ? screenSize.width / 50
                  : screenSize.width / 60,
            }}
            style={{
              marginTop: 10,
            }}
            onClick={capture}
            startIcon={isLoading && <CircularProgress size={20} />}>
            {isLoading ? "Loading..." : "Capture"}
          </Button>
          <Typography
            sx={{
              marginLeft: "5%",
              fontSize: screenSize.width / 45,
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
            style={{ marginTop: 10 }}>
            {ocrText}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OcrScreen;
