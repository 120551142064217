import React, { useEffect, useState } from "react";
import GaugeChart from "react-gauge-chart";

const Chart = (props) => {
  const data = props.data;
  const label = data?.label;
  const confidence = parseFloat(data?.confidence.toFixed(2));
  console.log(label, confidence);

  const [labelData, setLabelData] = useState("");

  useEffect(() => {
    if (parseFloat(data?.confidence.toFixed(2)) > 0.9) {
      setLabelData(data.label);
    } else {
      setLabelData("No Matches Found");
    }
  }, [props.data]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}>
      <h4 style={{ textAlign: "center", marginBottom: 0 }}>
        Confidence Level:{" "}
        {confidence ? `${(confidence * 100).toFixed(0)} %` : "0 %"}
      </h4>
      <h4 style={{ textAlign: "center", marginBottom: 0, marginTop: 0 }}>
        Model Name: {labelData == "No Matches Found" ? "" : label}
      </h4>
      <img
        src={
          labelData == "No Matches Found"
            ? "./images/NoRecordFound.jpg"
            : `./images/${label}.png`
        }
        style={{ width: "300px", height: "200px" }}
        alt={label}
      />
    </div>
  );
};
export default Chart;
